/* cousine-400normal - latin */
@font-face {
  font-family: 'Cousine';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Cousine Regular '),
    local('Cousine-Regular'),
    url('./files/cousine-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/cousine-latin-400.woff') format('woff'); /* Modern Browsers */
}

/* cousine-400italic - latin */
@font-face {
  font-family: 'Cousine';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Cousine Regular italic'),
    local('Cousine-Regularitalic'),
    url('./files/cousine-latin-400italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/cousine-latin-400italic.woff') format('woff'); /* Modern Browsers */
}

/* cousine-700normal - latin */
@font-face {
  font-family: 'Cousine';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Cousine Bold '),
    local('Cousine-Bold'),
    url('./files/cousine-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/cousine-latin-700.woff') format('woff'); /* Modern Browsers */
}

/* cousine-700italic - latin */
@font-face {
  font-family: 'Cousine';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Cousine Bold italic'),
    local('Cousine-Bolditalic'),
    url('./files/cousine-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/cousine-latin-700italic.woff') format('woff'); /* Modern Browsers */
}

